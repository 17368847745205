import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Invoice} from '../../../../models/invoice.interface';
import {getSingleResourceRelation} from '../../../../utils/json.util';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {computeInvoiceWeight, getLineItems} from '../../../../utils/invoice.util';
import {Address} from '../../../../models/address.interface';
import {WarningModalComponent} from "../../../shared/components/warning-modal/warning-modal.component";
import {ActivatedRoute} from "@angular/router";
import {INVOICE_TYPE_SMARTOP} from "../../../../utils/constants.util";
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'jet-invoice-shipping',
  templateUrl: './invoice-shipping.component.html',
  styleUrls: ['./invoice-shipping.component.scss']
})
export class InvoiceShippingComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public invoice: Invoice;
  public loading = false;
  public invoiceWeight = 0;
  public description = '';
  public zip = '';
  private bsModalRef: BsModalRef;
  private ngUnsubscribe = new Subject();
  private CARRIER_LABELS = ['XPO', 'ABFS', 'Saia', 'ODFL', 'FedEx Freight', 'Oak Harbor', 'MME'];


  public carriers = [];

  constructor(private bsModalService: BsModalService,
              private httpClient: HttpClient,
              private route: ActivatedRoute,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
  }

  ngOnInit() {
    this.assignments();
  }

  ngOnChanges() {
    this.assignments();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public getRates() {
    this.loading = true;
    this.buildTableStructure();

    const errors: Array<string> = [];

    if (this.invoiceWeight <= 0) {
      errors.push('Please enter an invoice weight.');
    }

    if (this.zip.trim().length !== 5 && this.zip.trim().length !== 6) {
      errors.push('Destination zipcode should be 5 or 6 digits');
    }

    if (errors.length) {

      this.carriers = [];
      this.loading = false;

      this.openWarningModal('Error Fetching Rates', errors);

      return;
    }

    // curl 'http://leisure-dev.limelyte.com/Internal/Managing/CRMS/STM/GetRates.aspx?EmpID=-1&Secure=ec5none2IrRoTNlyoyjnbgb&InvoiceID=238332&CustomerID=48669' --data '__EVENTTARGET=pntFreightRates%24btnActuallyGetRates&pntFreightRates%24txtDescription=151715&BodyContent_pntFreightRates_rntWeight_text=111.00&pntFreightRates%24rntWeight=111&pntFreightRates%24txtToZip=99201'
    const url: string = this.config.apiUrl + 'rates';
    const body = {
      'weight': this.invoiceWeight,
      'zip': this.zip,
      'description': this.description,
      'invoice_id': this.route.snapshot.queryParamMap.get('invoice_id'),
      'customer_id': this.route.snapshot.queryParamMap.get('customer_id'),
      'quote_id': this.generateQuoteID(),
    };

    this.CARRIER_LABELS.forEach(label => {
      if (label === this.CARRIER_LABELS[2]) {
        this.getRateFromJet('saia/', body, label);

        return;
      }

      if (label === this.CARRIER_LABELS[4]) {
        this.getRateFromJet('fedex/', body, label);

        return;
      }

      if (label === this.CARRIER_LABELS[5]) {
        this.getRateFromJet('oakharbor/', body, label);

        return;
      }

      if (label === this.CARRIER_LABELS[6]) {
        this.getRateFromJet('mme/', body, label);

        return;
      }

      body[label] = 1;

      // noinspection TypeScriptUnresolvedFunction
      this.httpClient.post<any>(url, body).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data: any) => {

        for (let i = 0; i < this.carriers.length; i++) {
            if (this.carriers[i].name === data[0].Company) {
              this.carriers[i] = {
                'name': data[0].Company,
                'base': '$' + data[0].DiscountRate.toFixed(2),
                'fuel': '$' + data[0].FS.toFixed(2),
                'total': '$' + data[0].TotalCost.toFixed(2),
                'tt': data[0].TT,
                'links': data[0].links,
                'hasData': data[0].DiscountRate !== 0
              };
          }
        }

        this.carriers.sort((a, b) => {
          const aTotal = parseFloat(a.total.slice(1, a.total.length));
          const bTotal = parseFloat(b.total.slice(1, b.total.length));

          return aTotal - bTotal;
        });
      }, (error: Error) => {
        for (let i = 0; i < this.carriers.length; i++) {
          if (this.carriers[i].name === label) {
            this.carriers[i] = {
              'name': label,
              'base': '$-1',
              'fuel': '$-1',
              'total': '$-1',
              'tt': '-1',
              'links': '',
              'hasData': true,
              'error': true,
            };
          }
        }
      });

      delete body[label];
    });
  }

  private buildTableStructure() {
    this.carriers = [];
    for (let i = 0; i < this.CARRIER_LABELS.length; i++) {
      this.carriers.push({
        'name': this.CARRIER_LABELS[i],
        'base': '',
        'fuel': '',
        'total': '',
        'tt': '',
        'links': '',
        'hasData': false,
      });
    }
  }

  private getRateFromJet(url, body, label) {
    this.httpClient.post(this.config.apiUrl + 'rates/' + url, body).subscribe((res) => {
      res = res['rateResponse'];

      for (let i = 0; i < this.carriers.length; i++) {
        if (this.carriers[i].name === res['Company']) {
          this.carriers[i] = {
            'name': res['Company'],
            'base': '$' + parseFloat(res['BaseRate']).toFixed(2),
            'fuel': '$' + parseFloat(res['FS']).toFixed(2),
            'total': '$' + parseFloat(res['TotalCost']).toFixed(2),
            'tt': res['TT'],
            'links': res['links'],
            'hasData': res['BaseRate'] !== 0
          };
        }
      }

      this.carriers.sort((a, b) => {
        const aTotal = parseFloat(a.total.slice(1, a.total.length));
        const bTotal = parseFloat(b.total.slice(1, b.total.length));

        return aTotal - bTotal;
      });
    },(error) => {
      for (let i = 0; i < this.carriers.length; i++) {
        if (this.carriers[i].name === label) {
          this.carriers[i] = {
            'name': label,
            'base': '$-1',
            'fuel': '$-1',
            'total': '$-1',
            'tt': '-1',
            'links': '',
            'hasData': true,
            'error': true,
          };
        }
      }
    });
  }

  private assignments() {
    if (!this.invoice) {
      return;
    }
    this.description                          = this.invoice.attributes.InvoiceNumber;
    // TODO: Need to account for line items changing.
    const invoiceLineItems: InvoiceLineItem[] = getLineItems(this.invoice);
    const isSmartop                      = this.invoice.attributes.InvoiceType === INVOICE_TYPE_SMARTOP;
    this.invoiceWeight = computeInvoiceWeight(invoiceLineItems, this.invoice.attributes.FreightNumberOfPallets, isSmartop, this.config.isUk);
    // TODO: Need to account for ship to zip changing.
    const shipToAddress: Address              = getSingleResourceRelation<Address>(this.invoice.relationships, 'shipToAddress');
    this.zip                                  = shipToAddress ? shipToAddress.attributes.ZIP : '';
  }

  private openWarningModal(title, messages) {
    const modalsCount = this.bsModalService.getModalsCount();
    // TODO: blur event on Freight Information fields firing multiple times. Guard against opening another one for now.
    if (modalsCount === 0) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = messages;
      this.bsModalRef.content.title = title;
    }

  }

  private generateQuoteID() {
    const now = new Date();
    const hours = now.getHours() * (60 * 60);
    const minutes = now.getMinutes() * 60;
    const seconds = now.getSeconds();

    return hours + minutes + seconds;
  }
}
